// 接口请求

import axios from './axios'

export const getMenu = (param) => {
	return axios.request({
		url: '/permission/getMenu',
		method: 'post',
		data: param
	})
}
export const getPermissionsList = (data) => {
	// 获取权限
	return axios.request({
		url: '/api/admin/getPermissionsList',
		method: 'post',
		data: data,
	})
}


export const logout = (data) => {
	return axios.request({
		url: '/api/admin/logout',
		method: 'post',
		data: data,
	})
}

// 创建分享配置
export const create_share_config = (data) => {
	return axios.request({
		url: '/api/admin/share_config/create',
		method: 'post',
		data: data,
	})
}

// 分享配置搜索
export const search_share_config = (data) => {
	return axios.request({
		url: '/api/admin/share_config/search',
		method: 'post',
		data: data,
	})
}
// 分享配置修改
export const update_share_config = (data) => {
	return axios.request({
		url: '/api/admin/share_config/update',
		method: 'post',
		data: data,
	})
}

export const gettype_list = (param) => { // 积分类型列表
	return axios.request({
		url: '/api/admin/integral/type_list',
		method: 'post',
		data: param
	})
}

export const getsearch_list = (param) => { // 搜索记积分列表(菜单)
	return axios.request({
		url: '/api/admin/integral/search_list',
		method: 'post',
		data: param
	})
}

export const getUserList = (param) => { // 获取用户列表
	return axios.request({
		url: '/api/admin/user/list',
		method: 'post',
		data: param
	})
}

export const delUser = (param) => { // 删除用户
	return axios.request({
		url: '/api/admin/user/del',
		method: 'post',
		data: param
	})
}

export const addUser = (param) => { // 新增用户
	return axios.request({
		url: '/api/admin/register',
		method: 'post',
		data: param
	})
}

export const editUser = (param) => { // 修改用户
	return axios.request({
		url: '/api/admin/user/edit',
		method: 'post',
		data: param
	})
}

export const rolelist = (param) => { // 角色list
	return axios.request({
		url: '/api/admin/role/list',
		method: 'post',
		data: param
	})
}

export const roledel = (param) => { // 角色del
	return axios.request({
		url: '/api/admin/role/del',
		method: 'post',
		data: param
	})
}

export const roleAdd = (param) => { // 角色add
	return axios.request({
		url: '/api/admin/role/add',
		method: 'post',
		data: param
	})
}

export const roleEdit = (param) => { // 角色Edit
	return axios.request({
		url: '/api/admin/role/edit',
		method: 'post',
		data: param
	})
}

export const userInfo = (param) => { // 查询用户
	return axios.request({
		url: '/api/admin/user/info',
		method: 'post',
		data: param
	})
}

export const roleInfoList = (param) => { // 角色类型列表
	return axios.request({
		url: '/api/admin/role/list',
		method: 'post',
		data: param
	})
}

export const r0search = (param) => { // R0阻值码查询
	return axios.request({
		url: '/api/admin/heat_r0/search',
		method: 'post',
		data: param
	})
}

export const r0save = (param) => { // R0新增
	return axios.request({
		url: '/api/admin/heat_r0/save',
		method: 'post',
		data: param
	})
}

export const r0del = (param) => { // R0删除
	return axios.request({
		url: '/api/admin/heat_r0/del',
		method: 'post',
		data: param
	})
}

export const unbindUnBind = (param) => { // 维修解绑unBind
	return axios.request({
		url: '/api/admin/unbind/unBind',
		method: 'post',
		data: param
	})
}

export const productionDown = (param) => { // 镭雕下载
	return axios.request({
		url: '/api/admin/production/down/' + param,
		method: 'get',
	})
}

export const mediaUpload = (param) => { // 图片上传
	return axios.request({
		url: '/api/admin/media/upload',
		method: 'post',
		data: param
	})
}

export const mediaSearch = (param) => { // 图片列表
	return axios.request({
		url: '/api/admin/media/search',
		method: 'post',
		data: param
	})
}

export const getimgUrl = (param) => { // get图片url
	return axios.request({
		url: '/api/admin/media/get_url',
		method: 'post',
		data: param
	})
}

export const mediaEditStatus = (param) => { // 修改img状态
	return axios.request({
		url: '/api/admin/media/edit_status',
		method: 'post',
		data: param
	})
}

export const homepageCreate = (param) => { // 首页创建
	return axios.request({
		url: '/api/admin/page/create',
		method: 'post',
		data: param
	})
}

export const getHomelist = (param) => { // 首页列表
	return axios.request({
		url: '/api/admin/page/list',
		method: 'post',
		data: param
	})
}

export const homedelete = (param) => { // 首页模块删除
	return axios.request({
		url: '/api/admin/page/delete',
		method: 'post',
		data: param
	})
}

export const homeEditStatus = (param) => { // 首页模块状态编辑
	return axios.request({
		url: '/api/admin/page/edit_status',
		method: 'post',
		data: param
	})
}

export const homeEdit = (param) => { // 首页模块编辑
	return axios.request({
		url: '/api/admin/page/edit',
		method: 'post',
		data: param
	})
}

export const articleCreate = (param) => { // 文章创建
	return axios.request({
		url: '/api/admin/article/create',
		method: 'post',
		data: param
	})
}


export const articleList = (param) => { // 文章列表
	return axios.request({
		url: '/api/admin/article/list',
		method: 'post',
		data: param
	})
}

export const articleEdit = (param) => { // 文章编辑
	return axios.request({
		url: '/api/admin/article/edit',
		method: 'post',
		data: param
	})
}

export const articleDelete = (param) => { // 文章删除
	return axios.request({
		url: '/api/admin/article/delete',
		method: 'post',
		data: param
	})
}

export const feedbackSearch = (param) => { // 反馈列表
	return axios.request({
		url: '/api/admin/feedback/search',
		method: 'post',
		data: param
	})
}

export const feedbackInfo = (param) => { // 反馈详情
	return axios.request({
		url: '/api/admin/feedback/info',
		method: 'post',
		data: param
	})
}

export const feedbackAudit = (param) => { // 反馈审核
	return axios.request({
		url: '/api/admin/feedback/audit',
		method: 'post',
		data: param
	})
}

export const goodsCreate = (param) => { // 商品创建
	return axios.request({
		url: '/api/admin/goods/create',
		method: 'post',
		data: param
	})
}

export const goodsSearch = (param) => { // 商品搜索
	return axios.request({
		url: '/api/admin/goods/search',
		method: 'post',
		data: param
	})
}

export const goodsDel = (param) => { // 商品删除
	return axios.request({
		url: '/api/admin/goods/del',
		method: 'post',
		data: param
	})
}

export const goodsEdit = (param) => { // 商品编辑
	return axios.request({
		url: '/api/admin/goods/edit',
		method: 'post',
		data: param
	})
}

export const goodsEditStatus = (param) => { // 商品编辑状态
	return axios.request({
		url: '/api/admin/goods/edit_status',
		method: 'post',
		data: param
	})
}

export const orderSearch = (param) => { // 订单搜索
	return axios.request({
		url: '/api/admin/order/search',
		method: 'post',
		data: param
	})
}

export const orderSend = (param) => { // 订单发货
	return axios.request({
		url: '/api/admin/order/send',
		method: 'post',
		data: param
	})
}

export const orderInfo = (param) => { // 订单详情
	return axios.request({
		url: '/api/admin/order/info',
		method: 'post',
		data: param
	})
}

export const login = (param) => {
	return axios.request({
		url: '/api/admin/login',
		method: 'post',
		data: param
	})
}

export const btSearch = (param) => {  // 蓝牙数据列表
	return axios.request({
		url: '/api/admin/bt/search',
		method: 'post',
		data: param
	})
}

export const btInfo = (param) => {  // 蓝牙数据详情
	return axios.request({
		url: '/api/admin/bt/info',
		method: 'post',
		data: param
	})
}

export const btSmokeList = (param) => {  // 蓝牙数据烧烟记录
	return axios.request({
		url: '/api/admin/bt/smoking_list',
		method: 'post',
		data: param
	})
}

export const btSmokeRecharge = (param) => {  // 蓝牙数据充电记录
	return axios.request({
		url: '/api/admin/bt/smoke_recharge',
		method: 'post',
		data: param
	})
}

export const btSmokeHeating = (param) => {  // 蓝牙数据温度列表，烧烟数据
	return axios.request({
		url: '/api/admin/bt/smoke_heating',
		method: 'post',
		data: param
	})
}

export const btSmokeHeatingInfo = (param) => {  // 蓝牙数据温度曲线数据
	return axios.request({
		url: '/api/admin/bt/smoke_heating_info',
		method: 'post',
		data: param
	})
}

export const BTsearch_total = (param) => { // 蓝牙搜索汇总列表
	return axios.request({
		url: '/api/admin/bt/search_total',
		method: 'post',
		data: param
	})
}

export const BTtotal_info = (param) => { // 蓝牙搜索汇总详情
	return axios.request({
		url: '/api/admin/bt/total_info',
		method: 'post',
		data: param
	})
}

export const BTsmoke_gear = (param) => { // 蓝牙调档数据
	return axios.request({
		url: '/api/admin/bt/smoke_gear',
		method: 'post',
		data: param
	})
}

export const userInfoSearch = (param) => { // 用户信息搜索
	return axios.request({
		url: '/api/admin/userInfo/search',
		method: 'post',
		data: param
	})
}

export const userInfoInfo = (param) => { // 用户信息详情
	return axios.request({
		url: '/api/admin/userInfo/info',
		method: 'post',
		data: param
	})
}

export const integralSearch = (param) => { // 搜索用户积分列表
	return axios.request({
		url: '/api/admin/integral/search',
		method: 'post',
		data: param
	})
}

// 设备用户使用记录(同一个设备被多个用户使用)
export const bt_product_use_list = (param) => { 
	return axios.request({
		url: '/api/admin/bt/product_use_list',
		method: 'post',
		data: param
	})
}

// 用过设备用户扫码记录
export const bt_product_inspection_list = (param) => { 
	return axios.request({
		url: '/api/admin/bt/product_inspection_list',
		method: 'post',
		data: param
	})
}
// 用过设备用户扫码记录
export const bt_user_product_use_list = (param) => { 
	return axios.request({
		url: '/api/admin/bt/user_product_use_list',
		method: 'post',
		data: param
	})
}

// 后台手动添加积分
export const integral_admin_create = (param) => { 
	return axios.request({
		url: '/api/admin/integral/admin_create',
		method: 'post',
		data: param
	})
}
// 创建问卷
export const questionnaire_createQuestionnaire = (param) => { 
	return axios.request({
		url: '/api/admin/questionnaire/createQuestionnaire',
		method: 'post',
		data: param
	})
}
// 获取问卷信息
export const questionnaire_getQuestionnaire = (param) => { 
	return axios.request({
		url: '/api/admin/questionnaire/getQuestionnaire',
		method: 'post',
		data: param
	})
}
// 搜索问卷
export const questionnaire_searchQuestionnaire = (param) => { 
	return axios.request({
		url: '/api/admin/questionnaire/searchQuestionnaire',
		method: 'post',
		data: param
	})
}
// 禁用问卷
export const questionnaire_closeQuestionnaire = (param) => { 
	return axios.request({
		url: '/api/admin/questionnaire/closeQuestionnaire',
		method: 'post',
		data: param
	})
}
// 获取用户填写问卷详情
export const questionnaire_getUserQuestionnaireDetail = (param) => { 
	return axios.request({
		url: '/api/admin/questionnaire/getUserQuestionnaireDetail',
		method: 'post',
		data: param
	})
}
// 获取用户填写问卷列表
export const questionnaire_searchUserQuestionnaireDetail = (param) => { 
	return axios.request({
		url: '/api/admin/questionnaire/searchUserQuestionnaireDetail',
		method: 'post',
		data: param
	})
}


// 奖品库搜索
export const prizes_search = (param) => { 
	return axios.request({
		url: '/api/admin/prizes/search',
		method: 'post',
		data: param
	})
}
// 奖品库创建
export const prizes_create = (param) => { 
	return axios.request({
		url: '/api/admin/prizes/create',
		method: 'post',
		data: param
	})
}
// 奖品库编辑
export const prizes_edit = (param) => { 
	return axios.request({
		url: '/api/admin/prizes/edit',
		method: 'post',
		data: param
	})
}
// 奖品库编辑状态
export const prizes_edit_status = (param) => { 
	return axios.request({
		url: '/api/admin/prizes/edit_status',
		method: 'post',
		data: param
	})
}
// 奖品库获取详情
export const prizes_info = (param) => { 
	return axios.request({
		url: '/api/admin/prizes/info',
		method: 'post',
		data: param
	})
}


// 抽奖配置创建
export const prize_draw_configs_create = (param) => { 
	return axios.request({
		url: '/api/admin/prize_draw_configs/create',
		method: 'post',
		data: param
	})
}
// 抽奖配置编辑
export const prize_draw_configs_edit = (param) => { 
	return axios.request({
		url: '/api/admin/prize_draw_configs/edit',
		method: 'post',
		data: param
	})
}
// 抽奖配置详情
export const prize_draw_configs_info = (param) => { 
	return axios.request({
		url: '/api/admin/prize_draw_configs/info',
		method: 'post',
		data: param
	})
}
// 中奖管理搜索
export const prize_draw_lists_search = (param) => { 
	return axios.request({
		url: '/api/admin/prize_draw_lists/search',
		method: 'post',
		data: param
	})
}
// 中奖管理发货
export const prize_draw_lists_deliver_goods = (param) => { 
	return axios.request({
		url: '/api/admin/prize_draw_lists/deliver_goods',
		method: 'post',
		data: param
	})
}
// 中奖管理领取并发货
export const prize_draw_lists_get_draw_and_deliver_goods = (param) => { 
	return axios.request({
		url: '/api/admin/prize_draw_lists/get_draw_and_deliver_goods',
		method: 'post',
		data: param
	})
}
// 中奖管理获取详情
export const prize_draw_lists_info = (param) => { 
	return axios.request({
		url: '/api/admin/prize_draw_lists/info',
		method: 'post',
		data: param
	})
}
// 统计用户填写问卷详情
export const questionnaire_stat = (param) => { 
	return axios.request({
		url: '/api/admin/questionnaire/stat',
		method: 'post',
		data: param
	})
}
// 获取用户填写问卷题目答案列表
export const questionnaire_getUserQuestionnaireList = (param) => { 
	return axios.request({
		url: '/api/admin/questionnaire/getUserQuestionnaireList',
		method: 'post',
		data: param
	})
}

// 搜索任务
export const task_searchTask = (param) => { 
	return axios.request({
		url: '/api/admin/task/searchTask',
		method: 'post',
		data: param
	})
}
// 编辑任务状态
export const task_updateTaskStatus = (param) => { 
	return axios.request({
		url: '/api/admin/task/updateTaskStatus',
		method: 'post',
		data: param
	})
}
// 创建任务
export const task_createTask = (param) => { 
	return axios.request({
		url: '/api/admin/task/createTask',
		method: 'post',
		data: param
	})
}
// 编辑任务
export const task_updateTask = (param) => { 
	return axios.request({
		url: '/api/admin/task/updateTask',
		method: 'post',
		data: param
	})
}

// 获取任务
export const task_getTask = (param) => { 
	return axios.request({
		url: '/api/admin/task/getTask',
		method: 'post',
		data: param
	})
}
// 
// 曲线记录
export const curve_search = (param) => { 
	return axios.request({
		url: '/api/admin/curve/search',
		method: 'post',
		data: param
	})
}
// 
export const user_address_list = (param) => { 
	return axios.request({
		url: '/api/admin/user_address/list',
		method: 'post',
		data: param
	})
}
export const getData = ()=>{
	return axios.request({
		url:'/home/getData',
	})
}
