<template>
  <div class="box">
    <div class="login-container">
      <el-button type="primary" @click="goto" class="login-submit">
        {{ code ? "返回小程序" : "跳转app" }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { login, getPermissionsList } from "../../api/data";
export default {
  name: "H5GotoApp",
  data() {
    return {
      form: {},
      code: "",
      uuid: "",
    };
  },
  mounted() {
    let obj = this.$route.query;
    console.log("接受到的路由数据", obj);
    this.code = "";
    this.uuid = "";
    if (obj.code) {
      this.code = obj.code;
    }
    if (obj.uuid) {
      this.uuid = obj.uuid;
    }
  },
  methods: {
    goto() {
      // // 先判断是不是微信端打开的
      if (/(micromessenger)/i.test(navigator.userAgent)) {
        // alert("微信");
      } else {
        // alert("普通浏览器");
        // 判断h5还是pc true就是h5
        let client =
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          );
        if (client) {
          // alert("h5");
        } else {
          confirm("请使用手机打开链接");
          return;
          // alert("pc");
        }
      }
      if (!this.code) {
        if (this.uuid) {
          // 跳转医疗app
          let userAgent =
            navigator.userAgent || navigator.vendor || window.opera;

          if (
            userAgent.match(/iPad/i) ||
            userAgent.match(/iPhone/i) ||
            userAgent.match(/iPod/i)
          ) {
            window.location = `https://cn.dl.gdjztech.com/authorize?unlink=http://dev.health.wonzi.com/H5GotoApp&appid=B254DAC2D396C316F7299BE2D8B16E385296D15B&uuid=${this.uuid}`;
            return "iOS";
          } else if (userAgent.match(/Android/i)) {
            window.location = `app://cn.dl.gdjztech.com/authorize?dplink=http://dev.health.wonzi.com/H5GotoApp&appid=B254DAC2D396C316F7299BE2D8B16E385296D15B&uuid=${this.uuid}`;
            return "Android";
          } else {
            return "unknown";
          }
          // **待办**
        } else {
          alert("链接异常，请重新复制后进入");
        }
      } else {
        this.submitCode();
      }
    },
    submitCode() {
      let that = this;
      var xhr = new XMLHttpRequest();
      // **待办**
      xhr.open("post", "http://dev.health.wonzi.com/Api/Wx/GetScheme");
      //3、设置 Content-Type属性（固定写法）
      xhr.setRequestHeader(
        "Content-Type",
        "application/x-www-form-urlencoded;"
      );
      // 正式版为"release"，体验版为"trial"  **待办**
      xhr.send(`env_version=trial&query=${"RingCode" + that.code}`);
      xhr.onreadystatechange = (res) => {
        // if (xhr.readyState == 4) {
        //   //监听readyState状态
        //   if (xhr.status == 200 || xhr.status == 0) {
        //     //监听HTTP状态码
        //     // console.log(JSON.parse(xhr.responseText).data.openlink);  //接收数据
        //     if (
        //       JSON.parse(xhr.responseText) &&
        //       JSON.parse(xhr.responseText).data &&
        //       JSON.parse(xhr.responseText).data.openlink
        //     ) {
        //       // location.replace(JSON.parse(xhr.responseText).data.openlink)
        //       window.location = JSON.parse(xhr.responseText).data.openlink;
        //     } else {
        //       confirm("接口异常, 请刷新后重试");
        //     }
        //   }
        // }
      };

      if (xhr.readyState == 4) {
        //监听readyState状态
        if (xhr.status == 200 || xhr.status == 0) {
          //监听HTTP状态码
          // console.log(JSON.parse(xhr.responseText).data.openlink);  //接收数据
          if (
            JSON.parse(xhr.responseText) &&
            JSON.parse(xhr.responseText).data &&
            JSON.parse(xhr.responseText).data.openlink
          ) {
            window.location = JSON.parse(xhr.responseText).data.openlink;
          } else {
            confirm("接口异常, 请刷新后重试");
          }
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #2d3a4b;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.login-container {
  border-radius: 15px;
  background-clip: padding-box;
  margin: 180px auto;
  width: 350px;
  padding: 35px 35px 15px 35px;
  background-color: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-title {
  margin: 0 auto 40px auto;
  text-align: center;
  color: #505458;
}
.login-submit {
  margin: 10px auto 0 auto;
}
</style>